import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { fetchToken, setToken } from "./Auth";
import { useState } from "react";
import { api, baseURL } from "./Auth";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import harp from "./assets/harp_dalle_transparent.png"

import axios from "axios";
import qs from "qs"

export default function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(true);
  let audio_id = searchParams.get("audio_id")
  //check to see if the fields are not empty
  const login = (e) => {
    console.log(e)
    e.preventDefault();
    if ((username == "") & (password == "")) {
      return;
    } else {
      // make api call to our backend. we'll leave thisfor later
      let payload = qs.stringify({
        username: username,
        password: password,
        grant_type: "",
        scope: "",
        client_id: "",
        client_secret: ""
      })
      axios
        .post(baseURL + "/token", payload)
        .then(function (response) {
          console.log(response.data.access_token, "response.data.token");
          if (response.data.access_token) {
            setToken(response.data.access_token);
            navigate("/get_audio?test=nein&audio_id=" + audio_id);
          }
        })
        .catch(function (error) {
          console.log(error, "error");
          setPassword("")
          // setUsername("")
          if (error.response.status == 401) {
            alert("Invalid Credentials!")
          }
          else {
            alert(error.response.status + ": " + error.response.statusText)
          }
          
        });
    }
  };
  const signOut = () => {
    sessionStorage.removeItem("access_token");
    delete api.defaults.headers.common["Authorization"];
    navigate("/");
  };
  return (
    <div  style={{ minHeight: 800, marginTop: 30 }}>
      {/* <h1>Welcome to Troubadisk </h1> */}
      <div className="loginWrapper" style={{ marginTop: 30 }}>
        {fetchToken() ? (
          <>
          <div style={{minWidth: "20rem", display: "flex", justifyContent:"space-around"}}>
          <Button 
          variant="primary" 
          type="button" 
          onClick={() => navigate("/get_audio?audio_id="+audio_id)}
          >
              Go to Download Page
            </Button>
            <Button 
          variant="primary" 
          type="button" 
          onClick={signOut}
          >
              Sign Out
            </Button>
          </div>
          </>
        ) : (
          <Card style={{ width: '25rem' }}>
          <Card.Img variant="top" src={harp} />
          <Card.Body>
            <Card.Subtitle className="mb-2 text-muted"><span style={{fontSize: "small"}}>Icon powered by generative AI</span></Card.Subtitle>
            <p></p>
            <Card.Title>Login</Card.Title>

            <Form onSubmit={(e) => login(e)}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter username"
                value={username}
                onChange={e => setUsername(e.target.value)}
                autoFocus={true}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                />
              <Form.Text className="text-muted">
                If you don't have credentials yet, please contact your administrator.
              </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
          </Card.Body>
        </Card>
        )
        }
      </div>
    </div>
  );
}