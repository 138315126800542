import './App.css';
import { useNavigate } from "react-router";

import { useSearchParams } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import AudioDownload from "./AudioDownload";
import { RequireToken } from "./Auth";
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/esm/Button';
import Image from 'react-bootstrap/Image'
import { api } from './Auth';
import harp from "./assets/harp_dalle_transparent.png"
function App() {

  // Get audio_id from url
  const [searchParams, setSearchParams] = useSearchParams();
  let audio_id = searchParams.get("audio_id")
  const navigate = useNavigate();

  const signOut = () => {
    sessionStorage.removeItem("access_token");
    delete api.defaults.headers.common["Authorization"];
    navigate("/?audio_id=" + audio_id)
  };
  return (
      <>
    <div style={{display: "flex", justifyContent: "end", width:"100%"}}>
      <Button 
          variant="primary" 
          type="button" 
          onClick={signOut}
          disabled={!sessionStorage["access_token"]}
          >
              Sign Out
            </Button>
      </div>
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/get_audio"
          element={
            <RequireToken>
              <AudioDownload />
            </RequireToken>
          }
        />
      </Routes>
    </div>
    </>
  );
}

export default App;
