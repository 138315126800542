import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { api, baseURL } from "./Auth";
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import harp from "./assets/harp_dalle_transparent.png"
import ListGroup from 'react-bootstrap/ListGroup';
import { useState, useEffect } from "react";

export default function AudioDownload() {
  const navigate = useNavigate();

  // Get audio_id from url
  const [searchParams, setSearchParams] = useSearchParams();
  let audio_id = searchParams.get("audio_id")
  const [audioFiles, setAudioFiles] = useState({"audios":[]})
  const [fetchedAudios, setFetchedAudios] = useState(false)
  
  // Remove access token from session storage and header
  const signOut = () => {
    sessionStorage.removeItem("access_token");
    delete api.defaults.headers.common["Authorization"];
    navigate("/?audio_id=" + audio_id)
  };

  useEffect(() => {
    if(!fetchedAudios){
      fetch(baseURL + "/get_all_audios_for_id?audio_id=" + audio_id,
        {
          headers: {
            "Authorization": `Bearer ${sessionStorage.getItem("access_token")}`
          }
        }
      ).then((resp) => {
        if(resp.status == 200){
          resp.json().then((data) => {
            // console.log(data)
            setAudioFiles(data)
          }
          )
        }else{
          if(resp.status == 401){
            alert("Session expired")
            signOut()
          }
          else{
            // console.log(resp)
            alert(resp.status + " " + resp.statusText)
            signOut()
          }
        }
      }
      )
    }
    setFetchedAudios(true)
    }); 

  const fetchAudios = () => {
    return true
  }
  // Fetch audio files from backend and download 
  const doFetchDownload = () => {
    fetch(baseURL + "/download_audio_file?audio_id=" + audio_id,
      {
        headers: {
          "Authorization": `Bearer ${sessionStorage.getItem("access_token")}`
        }
      }
    )
      .then((resp) => {
        if (resp.status == 200) {
          resp.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            // the filename you want
            a.download = audio_id + ".zip";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
            .catch(() => alert("oh no!"));
        } else {
          if (resp.status === 401) {
            alert("Session expired. You have to login again")
            signOut()
          }
          else{
            alert("An Error occurred. Received status" + resp.status + " from server")
          }
        }
      })

  };
  function UserUtter(props){
    if(props.index > 0){
      return <p>Anrufer: {props.text}</p>
    }
    else{
      return 
    }
  }
  function UserAudio(props){
    let audioRoute = "/get_single_audio?audio_id=" + props.audio_id
    let audioFileName = "audio_name=" + props.audio_name 
    let access_token = "access_token=" + sessionStorage.getItem("access_token")
    let finalRoute = baseURL + audioRoute + "&" + audioFileName + "&" + access_token

      if(props.index > 0){
        return <audio controls src={finalRoute}/>
      }
      else{
        return
      }
  }
  function UserListItem(props){
    if(props.index > 0){
      return (
        <ListGroup.Item as="li" key={props.index + "userItem"}>
          <UserUtter index={props.index} text={props.text} />
          <UserAudio index={props.index} audio_id={audio_id} audio_name={props.audio_name} />
        </ListGroup.Item>
      )
    }
    else{
      return
    }
  }
  function BotUtter(props){
    return <div>Bot: {props.text}</div>
  }
  return (
    <>
    <div style={{ marginTop: 20, minHeight: 700}}>
    <Image style={{height: "5em", widht: "5em"}} src={harp}/>
            <h1>Audio Explorer</h1>
            <div>
            <p>Selected Audio ID: {audio_id}</p>
        {fetchAudios() ?(
          <>
          <Button 
          variant="primary" 
          type="button" 
          onClick={doFetchDownload}
          disabled={audio_id ==null | audio_id == "null"}
          >
              Download all audios as .zip
            </Button>
          <div style={{marginTop: 20}}>
          <div style={{maxWidth: "50rem"}}>
            <ListGroup as="ol" variant="flush" >
            {
              Array.from(audioFiles["audios"]).map((a, index) => (
                <>
                <UserListItem index={index} text={a["user"]} audio_id={audio_id} audio_name={a["user_audio"]} ></UserListItem>
                <ListGroup.Item as="li" key={index}> 
                    {/* <UserUtter index={index} text={a["user"]} />
                    <UserAudio index={index} audio_id={audio_id} audio_name={a["user_audio"]} /> */}
                    <BotUtter index={index} text={a["bot"]} />
                </ListGroup.Item>
                </>
              ))
            }
          </ListGroup>
          </div>
          </div>
          
          </>
        ) : (
          
          <p>No audios found for ID : {audio_id} </p>
          )}
      </div>
    </div>
    </>
  );
}